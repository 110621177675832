import uuid from "react-uuid";

import {
  uploadAttachmentBySystem,
  uploadAttachment as uploadAttachmentToServer
} from "../../api";
import { addConversation, logEventRecord } from "./chatActions";
import { END_USER } from "../../constants/user";
import { CHAT_WITH_AGENT, MESSAGE_SENT } from "../../constants/chat";
import {
  ATTACHMENT_SUB_EVENT,
  ATTACHMENT_UPLOAD_FAILED_EVENT,
  ATTACHMENT_UPLOAD_START_EVENT,
  ATTACHMENT_UPLOAD_SUCCESS_EVENT
} from "../../constants/events";

export const attachmentActions = {
  RESET_ATTACHMENTS: "RESET_ATTACHMENTS",

  UPLOAD_ATTACHMENT_BEGIN: "UPLOAD_ATTACHMENT_BEGIN",
  UPLOAD_ATTACHMENT_SUCCESS: "UPLOAD_ATTACHMENT_SUCCESS",
  UPLOAD_ATTACHMENT_ERROR: "UPLOAD_ATTACHMENT_ERROR",

  ALLOW_ONE_TIME_UPLOAD: "ALLOW_ONE_TIME_UPLOAD",
  ONE_TIME_UPLOAD_SUCCESS: "ONE_TIME_UPLOAD_SUCCESS",
  ONE_TIME_UPLOAD_CLEAR: "ONE_TIME_UPLOAD_CLEAR",
  ONE_TIME_UPLOAD_FAILED: "ONE_TIME_UPLOAD_FAILED"
};

export const resetAttachments = () => {
  return {type: attachmentActions.RESET_ATTACHMENTS};
};

export const allowOneTimeUpload = (validation) => {
  return {type: attachmentActions.ALLOW_ONE_TIME_UPLOAD, payload: {validation}};
};

export const clearOneTimeUpload = () => {
  return {type: attachmentActions.ONE_TIME_UPLOAD_CLEAR};
};

export const uploadAttachment = (file, language, setBotTyping, sendJsonMessage) => {
  return (dispatch, getState) => {
    const messageId = uuid();
    const logEvent = (type, title) => {
      dispatch(
        logEventRecord("", null, uuid(), type, ATTACHMENT_SUB_EVENT, true, title)
      );
    }

    dispatch({type: attachmentActions.ONE_TIME_UPLOAD_CLEAR});

    const onError = () => {
      console.warn(`Readyly ChatAssist: failed to upload attachment "${file?.name}"`);
      dispatch({
        type: attachmentActions.UPLOAD_ATTACHMENT_ERROR,
        payload: { id: messageId }
      });
      logEvent(ATTACHMENT_UPLOAD_FAILED_EVENT, `Failed to upload ${file?.name}`);
    };

    try {
      const reduxState = getState();
      const orgDetails = reduxState?.org?.details;
      const chatWith = reduxState?.chat?.chatWith;
      const chatMedium = reduxState?.chat?.chatMedium;
      const conversationId = reduxState?.chat?.chatRecordId;
      const endUser = reduxState?.chat?.endUser;

      const readylySession = {
        orgName: reduxState?.org?.subdomain,
        conversationId,
        parentOrigin: reduxState?.org?.parentOrigin,
        platform: reduxState?.org?.platform,
        profile: reduxState?.org?.profile
      };
      logEvent(
        ATTACHMENT_UPLOAD_START_EVENT,
        `Uploading ${file?.name} to ${chatMedium}`
      );

      const fileReader = new FileReader();

      fileReader.onerror = onError;
      fileReader.onloadend = async () => {
        const b64String = fileReader.result;
        const index = b64String.indexOf(";base64,") + 8;

        try {
          if (chatWith === CHAT_WITH_AGENT) {
            await uploadAttachmentBySystem(
              orgDetails?.id,
              conversationId,
              chatMedium,
              b64String.slice(index),
              file.type,
              file.name,
              readylySession
            );
          } else {
            const response = await uploadAttachmentToServer(
              orgDetails?.id,
              conversationId,
              b64String.slice(index),
              file.type,
              file.name,
              readylySession
            );
            let query;
            if (response?.data?.publicUrl) {
              query = response.data.publicUrl;
            } else {
              query = "Failed to upload file!"
            }
            sendJsonMessage({
              action: "interact",
              orgid: orgDetails?.id,
              query,
              language,
              role: END_USER,
              endUser,
              personas: Array.isArray(endUser?.personas)
                ? endUser?.personas
                : []
            });
            dispatch(setBotTyping(true));
          }
          logEvent(
            ATTACHMENT_UPLOAD_SUCCESS_EVENT,
            `Uploaded ${file?.name} to ${chatMedium}`
          );
          dispatch({
            type: attachmentActions.UPLOAD_ATTACHMENT_SUCCESS,
            payload: { id: messageId }
          });
        } catch(error) {
          onError();
        }
      };

      dispatch({
        type: attachmentActions.UPLOAD_ATTACHMENT_BEGIN,
        payload: { id: messageId }
      });
      fileReader.readAsDataURL(file);
      dispatch(addConversation({
        id: messageId,
        type: END_USER,
        subtype: MESSAGE_SENT,
        message: file.name,
        attachment: {
          url: URL.createObjectURL(file),
          mimeType: file.type,
          name: file.name,
          local: true
        }
      }));
    } catch(error) {
      onError();
    }
  };
};
