const PHONE_NUMBER_PATTERN = /\b((\(?\d{3}\)?|\d{2,3})[\s.-]?\d{3,4}[\s.-]?\d{4}|\d{4,5}[\s.-]?\d{6}|\d{2}[\s]\d{6}|\d{1}[\s.-]\d{4}[\s.-]\d{4}|\d{1}([\s.-]\d{2}){4}|\d{5}\s\d{5}|\d{9,10})\b/g;
const PATTERNS = [
  // /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g, // Email addresses
  /\b(\d{4}[-\s]?){3}\d{4}|\d{4}\s\d{6}\s\d{5}\b/g, // Credit card numbers
  /\b(\d{9}|\d{3}[-.\s]+\d{2}[-.\s]+\d{4})\b/g // Social Security numbers
];

/**
 * @param {string} text 
 * @returns {string}
 */
export function censorPII(text) {
  if (typeof(text) !== "string") {
    return "";
  }
  for (const pattern of PATTERNS) {
    text = text.replace(pattern, "<redacted>");
  }
  return censorPhoneNumbers(text);
}

/**
 * @param {string} text 
 * @returns {string}
 */
function censorPhoneNumbers(text) {
  const matches = text.matchAll(PHONE_NUMBER_PATTERN);
  for (const match of matches) {
    try {
      const phoneNumber = match[0].split("");
      let digits = 4;
      for (let i = phoneNumber.length - 1; i >= 0; i--) {
        const c = phoneNumber[i];
        if (Number.isNaN(parseInt(c))) {
          continue;
        }
        if (digits > 0) {
          digits -= 1;
          continue;
        }
        phoneNumber[i] = "*";
      }
      text = text.replace(match[0], phoneNumber.join(""));
    } catch(error) {
      console.warn(`Readyly ChatAssist: ${error.message}`);
    }
  }
  return text;
}
