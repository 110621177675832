import axios from "axios";

import { authorizeSession } from "./authorize";
import {
  BASE_API_URL,
  API_ORG_INFO,
  ARTICLES_V2,
  EXTERNAL_ARTICLES,
  API_ORG_FT_ASSIST_CUSTOMIZATION_INFO,
  CREATE_TICKET,
  CREATE_CHAT_RECORD_V2,
  UPLOAD_ATTACHMENT,
  UPLOAD_LIVE_CHAT_ATTACHMENT,
  DOWNLOAD_LIVE_CHAT_ATTACHMENT,
  CREATE_EVENT_RECORD_V2,
  GET_IFRAME_WEBPAGE,
  SPEECH_OPERATIONS_API
} from "../constants/endpoints";

const chatAssistApi = axios.create({
  baseURL: BASE_API_URL
});
const retryQueue = [];

chatAssistApi.interceptors.request.use(chatAssistApiRequestInteceptor);
chatAssistApi.interceptors.response.use(undefined, chatAssistApiResponseInterceptor);

export async function getOrgData(org, readylySession = {}) {
  try {
    const result = await chatAssistApi.get(
      `${API_ORG_INFO}`,
      {params: {subdomain: `${org}`}, readylySession}
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getOrgCustomizations(
  org_name,
  profile,
  readylySession = {}
) {
  try {
    const result = await chatAssistApi.get(
      `${API_ORG_FT_ASSIST_CUSTOMIZATION_INFO}/${org_name}`,
      {params: {profile}, readylySession}
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getArticleContent(orgId, article, readylySession = {}) {
  try {
    const response = await chatAssistApi.get(
      `/${orgId}${ARTICLES_V2}/content/${article.articleId}`,
      {params: {version: article.version},  readylySession}
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getExternalArticleContent(
  orgId,
  system,
  baseUrl,
  articleId,
  personas = "[]",
  readylySession = {}
) {
  try {
    const response = await chatAssistApi.get(
      `${EXTERNAL_ARTICLES}/${system}/articles/content/${articleId}`,
      {params: {orgId, baseUrl, personas}, readylySession }
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function searchArticles(
  orgId,
  system,
  baseUrl,
  query,
  page = 1,
  perPage = 20,
  personas = "[]",
  readylySession = {}
) {
  try {
    const params = {
      orgId,
      baseUrl,
      query,
      page,
      perPage,
      personas
    };
    const response = await chatAssistApi.get(
      `${EXTERNAL_ARTICLES}/${system}/articles`,
      { params, readylySession }
    );
    return response?.data;
  } catch(error) {
    console.error(error);
    throw error;
  }
}

export async function titleSeachArticles(
  orgId,
  query,
  personas = "[]",
  readylySession = {}
) {
  try {
    const response = await chatAssistApi.get(
      `${EXTERNAL_ARTICLES}/title-search/articles`,
      {params: {orgId, query, personas}, readylySession }
    );
    return response?.data;
  } catch(error) {
    console.error(error);
    throw error;
  }
}

export async function getTopics(
  orgId,
  system,
  baseUrl,
  personas = "[]",
  readylySession
) {
  try {
    const response = await chatAssistApi.get(
      `${EXTERNAL_ARTICLES}/${system}/topics`,
      {params: {orgId, baseUrl, personas}, readylySession}
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getArticlesByTopic(
  orgId,
  system,
  baseUrl,
  topicId,
  page = 1,
  perPage = 20,
  personas = "[]",
  readylySession = {}
) {
  try {
    const params = {
      orgId,
      baseUrl,
      page,
      perPage,
      personas
    };
    const response = await chatAssistApi.get(
      `${EXTERNAL_ARTICLES}/${system}/topics/${topicId}`,
      {params, readylySession}
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createTicket(
  comment,
  ticketingSystem,
  validatedEmail,
  orgId,
  senderEmail = "",
  supportEmail = "",
  profile = "default",
  endUser = {},
  readylySession = {}
) {
  try {
    const data = {
      comment: comment,
      ticketing_system: ticketingSystem,
      validated_email: validatedEmail,
      org_id: orgId,
      origin: "chat_assist",
      sender_email: senderEmail,
      support_email: supportEmail,
      end_user :endUser,
      profile
    };
    const response = await chatAssistApi.post(CREATE_TICKET, data, {readylySession});
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createChatRecord(
  orgId,
  recordId,
  record,
  userEmail,
  feedback = {},
  interactions = [],
  conversationType = "",
  connectionType = "",
  endUser = {},
  profile = "",
  platform = "",
  origin = "",
  readylySession = {},
  retry = false
) {
  try {
    const data = {
      orgId,
      recordId,
      record,
      userEmail,
      feedback,
      interactions,
      tags: [],
      conversation_type: conversationType,
      connectionType,
      endUser,
      profile,
      platform,
      origin
    };

    const response = await chatAssistApi.post(
      CREATE_CHAT_RECORD_V2,
      data,
      {readylySession, _didRetry: !retry}
    );

    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createEventRecord(
  orgId,
  eventType,
  subtype,
  eventId,
  recordId,
  userEmail = "anonymous",
  title = "",
  query = "",
  meta = null,
  endUser = {},
  profile = "",
  platform = "",
  origin = "",
  readylySession = {}
) {
  try {
    const response = await chatAssistApi.post(
      CREATE_EVENT_RECORD_V2,
      {
        orgId,
        userEmail,
        eventType,
        subtype,
        eventId,
        recordId,
        title,
        query,
        meta,
        endUser,
        profile,
        platform,
        origin
      },
      {readylySession}
    );

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function uploadAttachment(
  orgId,
  conversationId,
  b64String,
  mimeType,
  filename,
  readylySession = {}
) {
  try {
    const response = await chatAssistApi.post(
      `${UPLOAD_ATTACHMENT}`,
      {orgId, conversationId, b64String, mimeType, filename},
      {readylySession}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function uploadAttachmentBySystem(
  orgId,
  conversationId,
  system,
  b64String,
  mimeType,
  filename,
  readylySession = {}
) {
  try {
    const response = await chatAssistApi.post(
      `${UPLOAD_LIVE_CHAT_ATTACHMENT}`,
      {orgId, system, conversationId, b64String, mimeType, filename},
      {readylySession}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function downloadSlackAttachment(
  orgId,
  url,
  mimeType,
  filename,
  readylySession = {}
) {
  try {
    const response = await chatAssistApi.post(
      `${DOWNLOAD_LIVE_CHAT_ATTACHMENT}`,
      {orgId, url, mimeType, filename},
      {readylySession}
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getIframeWebpage(
  url,
  mimeType,
  responseType,
  pointer = 0,
  readylySession = {}
) {
  try {
    const result = await chatAssistApi.get(GET_IFRAME_WEBPAGE, {
      params: {
        fetchOptions: JSON.stringify({
          targetURL: url,
          type: 'BLOB',
          pointer,
          axiosOptions: {headers: {Accept: mimeType},config: {responseType}}
        })
      },
      readylySession
    });
    return result?.data;
  } catch (error) {
      console.error(error);
      throw error;
  }
}

export async function textToSpeech(orgId, text, readylySession = {}) {
  try {
    const response = await chatAssistApi.post(
      `${SPEECH_OPERATIONS_API}/${orgId}/text-to-speech`,
      {text},
      {readylySession}
    );
    return response?.data;
  } catch(error) {
    console.error(error);
    throw error;
  }
}

export async function speechToText(
  orgId,
  b64Audio,
  type,
  readylySession = {}
) {
  try {
    const response = await chatAssistApi.post(
      `${SPEECH_OPERATIONS_API}/${orgId}/speech-to-text`,
      {b64Audio, type},
      {readylySession}
    );
    return response?.data;
  } catch(error) {
    console.error(error);
    throw error;
  }
}

function getCurrentSessionDetails(orgName, conversationId){
  try {
    const session = window.sessionStorage.getItem(`${orgName}_${conversationId}`);
    return JSON.parse(session) ?? {};
  } catch(error) {
    console.error("Readyly ChatAssist: Failed to read credentials!");
    return {};
  }
}

function chatAssistApiRequestInteceptor(config) {
  if (!config?.readylySession?.orgName || !config?.readylySession?.conversationId) {
    return config;
  }

  const { orgName, conversationId, parentOrigin } = config?.readylySession || {};
  const session = getCurrentSessionDetails(orgName, conversationId);

  config.headers = {
    ...config.headers,
    "X-Parent-Origin": parentOrigin,
    "Authorization": `Bearer ${session?.accessToken}`
  };

  return config;
}

async function chatAssistApiResponseInterceptor(error) {
  const { config, response } = error;

  // failure is not due to missing/expired token
  if (response?.status !== 403) {
    return Promise.reject(error);
  }

  const { orgName, conversationId } = config?.readylySession ?? {};
  const { refreshing } = getCurrentSessionDetails(orgName, conversationId);

  // queue incoming requests while token is refreshed
  if (refreshing) {
    if (config?._didRetry) {
      return Promise.reject(error);
    }
    return new Promise((resolve, reject) => {
      config._didRetry = true;
      retryQueue.push({
        config,
        resolve,
        reject
      });
    });
  }

  // refresh token if necessary even if request was already retried
  await authorizeSession(
    config?.readylySession?.orgName,
    config?.readylySession?.conversationId,
    config?.readylySession?.platform,
    config?.readylySession?.profile,
    config?.readylySession?.parentOrigin
  );
  clearRetryQueue();

  if (config?._didRetry) {
    return Promise.reject(error);
  }

  config._didRetry = true;

  return chatAssistApi(config);
}

function clearRetryQueue() {
  while(retryQueue.length) {
    const { config, resolve, reject } = retryQueue.shift();
    chatAssistApi(config)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  }
}
