export const LIKE_EVENT = "like";
export const DISLIKE_EVENT = "dislike";

export const WIDGET_READY_EVENT = "load";
export const WIDGET_AUTO_LAUNCH_EVENT = "auto launch";

export const WIDGET_OPEN_EVENT = "launch";
export const WIDGET_CLOSE_EVENT = "collapse";
export const WIDGET_EXPAND_EVENT = "expand";
export const WIDGET_NARROW_EVENT = "narrow";

export const INITIAL_PROMPT_EVENT = "initial prompt";
export const RESET_CHAT_EVENT = "reset chat";
export const EXECUTE_WORKFLOW_EVENT = "execute workflow";
export const ESCALATION_EVENT = "escalation";
export const MISHIT_EVENT = "mishit";
export const SUBMIT_TICKET_EVENT = "submit ticket";
export const URGENT_QUERY_EVENT = "urgent query";
export const HEARTBEAT_FAILED = "heartbeat failed";

export const VIEW_ARTICLE_EVENT = "view article";
export const VIEW_ORIGINAL_EVENT = "view source";

export const WORKFLOW_AUTO_EXECUTE = "auto execute workflow";
export const WORKFLOW_START_EVENT = "workflow start";
export const WORKFLOW_SUCCESS_EVENT = "workflow end";
export const WORKFLOW_FAILED_EVENT = "workflow failed";

export const FORM_VIEW_EVENT = "view form";
export const FORM_SUBMIT_EVENT = "submit form";
export const FORM_CANCEL_EVENT = "cancel form";

export const TICKET_CREATED_EVENT = "ticket created";
export const TICKET_FAILED_EVENT = "ticket failed";

export const OPEN_CHAT_TAB_EVENT = "chat tab";
export const OPEN_RESOURCE_TAB_EVENT = "resource tab";
export const VIEW_TOPIC_EVENT = "view topic";
export const SEARCH_EVENT = "search";

export const RECORDING_START_EVENT = "start recording";
export const RECORDING_END_EVENT = "end recording";
export const RECORDING_ABORT_EVENT = "abort recording";
export const SPEECH_TO_TEXT_START_EVENT = "speech-to-text start";
export const SPEECH_TO_TEXT_SUCCESS_EVENT = "speech-to-text success";
export const SPEECH_TO_TEXT_FAILED_EVENT = "speech-to-text failed";
export const TEXT_TO_SPEECH_START_EVENT = "text-to-speech start";
export const TEXT_TO_SPEECH_SUCCESS_EVENT = "text-to-speech success";
export const TEXT_TO_SPEECH_FAILED_EVENT = "text-to-speech failed";
export const READ_ALOUD_START_EVENT = "read aloud start";
export const READ_ALOUD_PAUSE_EVENT = "read aloud pause";
export const READ_ALOUD_RESUME_EVENT = "read aloud resume";
export const READ_ALOUD_STOP_EVENT = "read aloud stop";

export const SWITCH_LANGUAGE_EVENT = "switch language";

export const ATTACHMENT_SELECTED_EVENT = "select attachment";
export const ATTACHMENT_REMOVED_EVENT = "remove attachment";
export const ATTACHMENT_UPLOAD_START_EVENT = "upload attachment start";
export const ATTACHMENT_UPLOAD_SUCCESS_EVENT = "upload attachment success";
export const ATTACHMENT_UPLOAD_FAILED_EVENT = "upload attachment failed";
export const ATTACHMENT_DOWNLOAD_START_EVENT = "download attachment start";
export const ATTACHMENT_DOWNLOAD_SUCCESS_EVENT = "download attachment success";
export const ATTACHMENT_DOWNLOAD_FAILED_EVENT = "download attachment failed";

export const RESIZE_SUB_EVENT = "resize";
export const WIDGET_SUB_EVENT = "widget";
export const REPLY_OPTION_SUB_EVENT = "reply prompt";

export const ARTICLE_SUB_EVENT = "article";
export const INTENT_SUB_EVENT = "intent";
export const INTERACT_SUB_EVENT = "interact";
export const TITLE_SEARCH_SUB_EVENT = "title search";

export const WORKFLOW_SUB_EVENT = "workflow";
export const TICKET_SUB_EVENT = "ticket";

export const TAB_CHANGE_SUB_EVENT = "change tab";
export const RESOURCE_SECTION_SUB_EVENT = "resource section";

export const VOICE_CHAT_SUB_EVENT = "voice chat";
export const LOCALIZATION_SUB_EVENT = "localization";
export const ATTACHMENT_SUB_EVENT = "attachment";
export const ERROR_SUB_EVENT = "error";