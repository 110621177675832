import produce from "immer";

import { attachmentActions } from "../actions/attachmentActions";

const initialState = {
  allowOneTimeUpload: false,
  validation: {},
  files: {}
};

const chatReducer = produce((draft, action) => {
  switch(action.type) {
    case attachmentActions.UPLOAD_ATTACHMENT_BEGIN:
      draft.files[action.payload.id] = { isLoading: true, failed: false };
      return;
    case attachmentActions.UPLOAD_ATTACHMENT_ERROR:
      draft.files[action.payload.id] = { isLoading: false, failed: true };
      return;
    case attachmentActions.UPLOAD_ATTACHMENT_SUCCESS:
      draft.files[action.payload.id] = { isLoading: false, failed: false };
      return;
    case attachmentActions.RESET_ATTACHMENTS:
      draft.files = {};
      return;
    case attachmentActions.ALLOW_ONE_TIME_UPLOAD:
      draft.validation = action.payload?.validation;
      draft.allowOneTimeUpload = true;
      return;
    case attachmentActions.ONE_TIME_UPLOAD_CLEAR:
    case attachmentActions.ONE_TIME_UPLOAD_SUCCESS:
      draft.validation = {};
      draft.allowOneTimeUpload = false;
      return;
    case attachmentActions.ONE_TIME_UPLOAD_FAILED:
      return;
    default:
      return;
  }
}, initialState);

export default chatReducer;
