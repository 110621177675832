import uuid from "react-uuid";

import { speechToText } from "../../api";
import speechRecorderService from "../../services/speechRecorder";

import { pauseUtterance } from "./speechSynthesisActions";
import { addConversation, setBotTyping, logEventRecord } from "./chatActions";
import { AI_BOT } from "../../constants/user";
import { MESSAGE_RECEIVED } from "../../constants/chat";
import {
  RECORDING_START_EVENT,
  SPEECH_TO_TEXT_START_EVENT,
  SPEECH_TO_TEXT_FAILED_EVENT,
  SPEECH_TO_TEXT_SUCCESS_EVENT,
  VOICE_CHAT_SUB_EVENT
} from "../../constants/events";

export const mediaRecorderActions = {
  AUDIO_RECORDING_BEGIN: "AUDIO_RECORDING_BEGIN",
  AUDIO_RECORDING_SUCCESS: "AUDIO_RECORDING_SUCCESS",
  AUDIO_RECORDING_ERROR: "AUDIO_RECORDING_ERROR",
  AUDIO_RECORDING_ABORT: "AUDIO_RECORDING_ABORT",

  TRIED_AUDIO_RECORDING: "TRIED_AUDIO_RECORDING"
};

export const triedAudioRecording = () => {
  return { type: mediaRecorderActions.TRIED_AUDIO_RECORDING };
};

export const startAudioRecording = (handleQuery) => {
  return async (dispatch, getState) => {
    if (getState()?.speech?.speaking) {
      dispatch(pauseUtterance());
    }

    const handlers = {
      "error": () => {
        console.warn(
          "Readyly ChatAssist: an unexpected error occured while recording audio!"
        );
        dispatch(abortAudioRecording());
        displayRecordingError(dispatch);
      },
      "stop": () => {
        console.warn("Readyly ChatAssist: audio recording stopped unexpectedly!");
        dispatch(abortAudioRecording());
        displayRecordingError(dispatch, "Audio recording stopped unexpectedly");
      },
      "pause": () => {
        console.warn("Readyly ChatAssist: audio recording paused unexpectedly!");
        dispatch(abortAudioRecording());
        displayRecordingError(dispatch, "Audio recording paused unexpectedly");
      },
      "dataavailable": (_, maxLimitReached) => {
        if (!maxLimitReached) {
          return;
        }
        dispatch(audioRecordingEnd(handleQuery));
      }
    };

    try {
      dispatch(logEventRecord(
        "",
        null,
        uuid(),
        RECORDING_START_EVENT,
        VOICE_CHAT_SUB_EVENT,
        true,
        ""
      ));
      await speechRecorderService.start(handlers);
    } catch(error) {
      console.warn(error.message);
      dispatch(abortAudioRecording());
      displayRecordingError(dispatch, error.message);
      return;
    }

    dispatch({ type: mediaRecorderActions.AUDIO_RECORDING_BEGIN });
  };
};

export const audioRecordingEnd = (handleQuery) => {
  return async (dispatch, getState) => {
    const reduxState = getState();

    await handleAudioMessage(
      reduxState?.org?.details?.id,
      dispatch,
      handleQuery,
      {
        orgName: reduxState?.org?.subdomain,
        conversationId: reduxState?.chat?.chatRecordId,
        platform: reduxState?.org?.platform,
        profile: reduxState?.org?.profile,
        parentOrigin: reduxState?.org?.parentOrigin
      }
    );
  
    dispatch({ type: mediaRecorderActions.AUDIO_RECORDING_SUCCESS });
  };
};

export const abortAudioRecording = () => {
  if (speechRecorderService.recording || speechRecorderService.paused) {
    speechRecorderService.abort();
  }

  return { type: mediaRecorderActions.AUDIO_RECORDING_ABORT };
};

const handleAudioMessage = async (orgId, dispatch, handleQuery, readylySession = {}) => {
  const recording = await speechRecorderService.stop();

  if (!recording?.blob) {
    return;
  }

  const { blob, mimeType } = recording;
  const type = mimeType.split(";")[0].split("/")[1];

  dispatch(logEventRecord(
    "",
    null,
    uuid(),
    SPEECH_TO_TEXT_START_EVENT,
    VOICE_CHAT_SUB_EVENT,
    true,
    ""
  ));

  if (!window.FileReader) {
    console.warn(
      "Readyly ChatAssist: this browser lacks the features required to process audio!"
    );
    displayRecordingError(
      dispatch,
      "This browser lacks the features required to process audio"
    );
    return;
  }

  dispatch(setBotTyping(true));

  const fileReader = new FileReader();
  fileReader.readAsDataURL(blob);
  fileReader.onloadend = async () => {
    const b64Audio = fileReader.result;
    const index = b64Audio.indexOf(";base64,") + 8;
    let text = "";

    try {
      const response = await speechToText(orgId, b64Audio.slice(index), type, readylySession);
      text = response.data;
    } catch(error) {
      console.warn("Readyly ChatAssist: Failed to process recorded audio!");
      displayRecordingError(dispatch, "Failed to process recorded audio");
      return;
    }

    dispatch(logEventRecord(
      text,
      null,
      uuid(),
      SPEECH_TO_TEXT_SUCCESS_EVENT,
      VOICE_CHAT_SUB_EVENT,
      true,
      ""
    ));
    dispatch(setBotTyping(false));
    handleQuery(text, true);
  };
  fileReader.onerror = () => {
    console.warn("Readyly ChatAssist: failed to process recorded audio!");
    displayRecordingError(dispatch, "Failed to process recorded audio");
  };
};

const displayRecordingError = (
  dispatch,
  message = "An unexpected error occured while recording audio"
) => {
  dispatch(setBotTyping(false));
  dispatch(logEventRecord(
    "",
    null,
    uuid(),
    SPEECH_TO_TEXT_FAILED_EVENT,
    VOICE_CHAT_SUB_EVENT,
    true,
    message
  ));
  dispatch(addConversation({
    type: AI_BOT,
    subtype: MESSAGE_RECEIVED,
    message: message,
    autoTextToSpeech: true
  }));
};
