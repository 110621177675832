import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import uuid from "react-uuid";

import VolumeUpIcon from '@mui/icons-material/VolumeUpOutlined';
import PauseCircleIcon from '@mui/icons-material/PauseCircleOutlined';
import { CircularProgress } from "@mui/material";

import {
  speakUtterance,
  resumeUtterance,
  pauseUtterance,
  loadAudio
} from "../../../redux/actions/speechSynthesisActions";
import { logEventRecord } from "../../../redux/actions/chatActions";
import useLocalization from "../../../hooks/localization";
import {
  READ_ALOUD_PAUSE_EVENT,
  READ_ALOUD_RESUME_EVENT,
  VOICE_CHAT_SUB_EVENT
} from "../../../constants/events";

const IconButton = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 1px 2px;
  background-color: transparent;
  box-shadow: none;
  text-transform: none;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: ${({ isLoading }) => isLoading ? "wait" : "pointer"};
  color: ${({ theme }) => theme.darkGrey};
  ${({ isLoading, theme }) => !isLoading && `
    &:hover {
      color: ${theme.primaryColor};
    }
  `}
`;

function TextToSpeech({ textId, text, fontSize }) {
  const content = useSelector((state) => state?.speech?.content);
  const speaking = useSelector((state) => state?.speech?.speaking);
  const paused = useSelector((state) => state?.speech?.paused);
  const loadingAudio = useSelector((state) => state?.speech?.loadingAudio);

  const tooltips = useSelector(
    (state) => state?.org?.customizations?.tooltips?.voiceChat
  );

  const dispatch = useDispatch();
  const theme = useTheme();
  const { localizeText } = useLocalization();

  const isSpeakingContent = textId === content?.id;
  const isLoading = loadingAudio?.some((id) => id === textId);

  const logEvent = (type) => {
    dispatch(logEventRecord("", null, uuid(), type, VOICE_CHAT_SUB_EVENT, true, ""));
  };

  const handleClick = () => {
    if (isLoading) {
      return;
    }

    if (!isSpeakingContent) {
      dispatch(loadAudio(textId));
      dispatch(speakUtterance(textId, text));
      return;
    }

    if (speaking) {
      dispatch(pauseUtterance());
      logEvent(READ_ALOUD_PAUSE_EVENT);
    } else if (paused) {
      logEvent(READ_ALOUD_RESUME_EVENT);
      dispatch(resumeUtterance());
    }
  };

  const iconStyle = { fontSize: fontSize || theme.lg };
  const loaderStyle = {
    color: theme.primaryColor,
    position: "absolute",
    top: "-2px",
    left: "-1px"
  };

  const isSpeaking = isSpeakingContent && speaking;

  return (
    <Tooltip
      placement="top"
      title={isSpeaking
        ? localizeText(tooltips?.pause)
        : localizeText(tooltips?.play)}
    >
      <IconButton onClick={handleClick} isLoading={isLoading}>
          {isSpeaking
            ? (<PauseCircleIcon sx={iconStyle} />)
            : (<VolumeUpIcon sx={iconStyle} />)}
          {isLoading && (
            <CircularProgress
              size={theme.xxl}
              sx={loaderStyle} />
          )}
      </IconButton>
    </Tooltip>
  );
}

export default TextToSpeech;
